.th{
    background-color: lightpink;
    /* width: 10%; */
    text-align: left;
}

.Container{
    margin:2% 5% 5% 5%
}
.bnn{
  margin-left: 10px;
}

.bnn:hover{
  cursor: pointer;
}

.input_group{
    width: 30%;
    padding: 0px 0px 5px 0px;
    /* margin-left: 70%; */
    align-content: end;
}

.table_bordered tbody tr:nth-child(even) {
  background-color: green; /* Background color for even rows */
}
.table_bordered tbody tr:nth-child(odd) {
  background-color: red; /* Background color for odd rows */
}
.medal{
    /* border: 2px solid #01519a; */
    /* box-shadow: 5px 10px #01519a; */
    border-radius: 20px;
    margin: 10px;
    padding-bottom:10px ;
    /* background-color: red; */
  	width: 90%;

}

/* .tableinner{
    padding-Top: 50%;
} */

/* tr{
    border: 1px solid teal;
    padding: 10px;
} */
.tablecell{
        text-align: center !important; /* Center content horizontally */
        vertical-align: middle !important; /* Center content vertically */
        font-weight: 500 !important;

    }

    @media (max-width: 770px) {
        .tablecell {
          /* display: flex; */
          /* flex-direction: column !important; */
          align-items: center !important;
          font-size:8px !important;
          margin: 10px !important;
        }
        .medal{
          width: 100%;
        }
      }
.medalth{
  background-color:white ;
  width: 25%;

}
.texttt{
  position: relative;
  bottom: 2.5rem;
}
.issue{
  background-color:white ;
  width: 25%;
  border: 2px solid blue;
  text-align: center;
  justify-content: center;
  align-items: center;

}

@media (max-width: 488px) {
  .texttt{
    position: relative;
    bottom: .8rem;
  }
}
      .medaltd{
        font-weight: 800;
        background-color:#d7e7ff ;
      }

@media (max-width: 488px) {
    .tablecell {
      /* display: flex; */
      /* flex-direction: column !important; */
      align-items: center !important;
      font-weight: 600;
      font-size:8px !important;
      margin: 10px !important;
    }
  }


  @media (max-width: 380px) {
    .tablecell {
      /* flex-direction: column !important; */
      align-items: center !important;
      font-weight: 600;

      font-size:6px !important;
      margin: 6px !important;
    }
  }


