.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* importing the css from the index filr globally*/


/* chat bot  */


#chat-bot-container{
  position: absolute;
  top:800px;
  right:10px
}

    /* media for facebook and twitter */


    @media only screen and (min-width: 320px) and (max-width: 768px) {

      #facebook-container {
        width: 90% !important;
        margin: auto !important;
        /* border: 2px solid orangered; */
      }
    }


    /* @media only screen and (min-width: 320px) and (max-width: 420px) {

      #facebook-container {
        width: 250px !important;
        margin: auto !important;

      }
    } */

    #facebook-container {
      width: 100% !important;
      margin: auto !important;
      border: 1px solid #cfd9de;
      /* margin:10px !important; */
      border-radius: 10px !important;
    }


    /* ------------------------------------ */
    #header .btn-get-started:hover {
      background: #3498db;
      color:#fff;
    }
    .header-img {
      text-align: right;
    }
    #header .animated{
      animation: up-down 2s ease-in-out infinite alternate-reverse both;
    }


    #header h1{
      color: red;
    }
    /* =============== */







    /* Apply now button  */


    .carousel-caption__areaTitle {
      background-color: #468cc7;
      display: inline;
      color: #fff;
    }

    .apply-now-button {
      background-color: #468cc7;
      color: #fff;
      border-radius: 10px;
      padding: 10px 15px;
      border: none;
    }


    /* .apply-now-button{
  position:absolute;
  right:5%;
} */

    .apply-now-button:hover {
      background-color: #0a66c2;
    }



    /* Achiever card shadow */


    .achiever-card {
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      /* border-radius: 12px; */
    }




    /* responsiveness for navbar */





    @media only screen and (min-width: 320px) and (max-width: 768px) {

      .logo-111 {
        display: none !important;
      }
    }





    @media only screen and (min-width: 320px) and (max-width: 620px) {

      .siteheader {
        background-image: none !important;
      }
    }












    /* Navbar style */

    .siteheader {

      /* background-attachment: fixed; */
      background-position: center;
      background-repeat: no-repeat;
      /* min-height: 700px; */
      background-size: contain !important;
      /* width:100%; */
      height: auto;
      /* background-image: url("./img/navbar-bg-img.jpg"); */
      /* background-image: url("./img/top-navbar-img.jpg"); */

    }




    /* Parallex animation */

    .servicesSection {
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      /* min-height: 700px; */
      background-size: cover !important;
      height: auto;
      /* background-image: url("https://sportsauthorityofindia.nic.in/sai/assets/frontend/images/focus/focus-bg2.jpg"); */
      /* padding: 90px 0 90px; */
      /* background-image: url("./img/parallax-final.jpg"); */
    }

    .servicesSection h4 {

      color: #fff;
    }


    /* footer parallax animation */


    .footer-top-bg {
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      /* min-height: 700px; */
      background-size: cover !important;
      height: auto;
      /* background-image: url("https://sportsauthorityofindia.nic.in/sai/assets/frontend/images/focus/focus-bg2.jpg"); */
      /* padding: 90px 0 90px; */
      /* background-image: url("./img/parallax-footer.jpg"); */
    }







    /* image animation  */



    .infra-carousal-slide:hover {
      --_i: 100%;
      transition: .2s, background-size .2s .2s;
    }






    .img-animate {
      --c: #016938;
      /* the border color */
      --b: 2px;
      /* the border thickness*/
      --g: 1px;
      /* the gap on hover */

      padding: calc(var(--g) + var(--b));
      --_g: #0000 25%, var(--c) 0;
      background:
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
        conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0 var(--_i, 200%)/var(--_i, var(--b)) 200% no-repeat;
      transition: .2s, background-position .2s .2s;
      cursor: pointer;
    }

    img:hover {
      --_i: 100%;
      transition: .2s, background-size .2s .2s;
    }









    .zoom {
      padding: 4px;
      /* background-color: green; */
      transition: transform .2s;
      /* Animation */
      width: 100%;
      /* height: 80%; */
      margin: 0 auto;
    }

    .zoom:hover {
      transform: scale(1.07);
      /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }



    #myModal {
      background-color: rgba(0, 0, 0, 0.47);
    }

    #myModal .modal-content {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
      border: 0px solid rgba(0, 0, 0, 0);
      margin-top: 12rem;
    }

    #myModal .modal-header {
      border-bottom: 0px solid #e5e5e5;
      padding: 8px 15px;
    }

    #myModal .modal-body {
      padding: 0px 15px 15px;
    }

    #myModal .modal-footer {
      text-align: center;
      border-top: 0px solid #e5e5e5;
      padding: 0;
    }

    #myModal .close {
      color: #000;
      opacity: 2;
    }


    #cursor {
      position: fixed;
      top: -5px;
      left: -5px;
      width: 10px;
      height: 10px;
      background-color: white;
      border-radius: 50%;
      pointer-events: none;
      z-index: 999;
    }

    #cursor-border {
      --size: 50px;
      position: fixed;
      top: calc(var(--size) / -2);
      left: calc(var(--size) / -2);
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      box-shadow: 0 0 0 1px white;
      pointer-events: none;
      transition: top 0.15s ease-out, left 0.15s ease-out, width 0.15s ease-out,
        height 0.15s ease-out, background-color 0.15s ease-out;
      z-index: 999;
    }





    .social-network a.icoFacebook {
      background-color: #3B5998;
    }


    .social-network a.icoTwitter {
      background-color: #3cf
    }


    .social-network a.icoyoutube {
      background-color: #c6181e;
    }


    .social-network a.icoinstagram {
      background-color: #d22f71;
    }

    .social-network a.icoLinkedIn {
      background-color: #0a66c2;
    }


    .social-network a.icoGoogleMap {
      background-color: #fff;
    }


    /* .media-icon :hover{
      -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    -ms-transition: all .2s;
    transition: all .2s
    } */



    /* .social-circle li a img:hover
.triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    -ms-transition: all .2s;
    transition: all .2s
} */





    .hover-rotate {
      overflow: hidden;
      /* margin: 8px; */
      /* min-width: 15px; */
      /* max-width: 15px; */
      /* width: 100%; */
    }

    .hover-rotate img {
      transition: all 0.3s;
      box-sizing: border-box;
      max-width: 100%;
    }

    .hover-rotate:hover img {
      transform: scale(1.3) rotate(360deg);
    }


    /* .top-dropdown:hover{
          color: ;
        }

        .dropdown-menu.login:hover{
          background-color: #01519a;
        } */


    /* counter animation */




    /* code for about us page */



    /* gallery page css */


    .g-imag{
      transition: transform .2s; /* Animation */
      /* width: 200px;
      height: 200px; */
      margin: 0 auto;
      border-radius: 8px;
    }
    .g-imag:hover{
      transform: scale(1.06);
    }



    .sticky_header{
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      transition: #fff 0.3s ease-in-out;
      /* background-color: #3B5998; */

    }

/* 

    .sticky_header {
      position: fixed;
      top: 10px;
      left: 0;
      width: 100%;
      transition: background-color 0.3s ease-in-out;
      z-index: 10;
    }

     */
    
    .sticky_header.scrolled {
      background-color:#fff;
      /* transition: #fff 0.3s ease-in-out; */
      z-index: 10;
    }
    





