.swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  /* #app {
    height: 100%;
  } */
  /* html, */
  .mySwiper body {
    position: relative;
    height: 100%;
    background-color: red;
  }

  .mySwiper body{
    /* Your other styles here */

    /* Change the color of the navigation arrows */
    .swiper-button-next, .swiper-button-prev, .swiper-button-prev {
      background-color: red !important; /* Change this to the color you want */
    }
  }


  .swiper-slide h4{
    color: white;
  }
  /* body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } */



/* styles.css or your CSS file */
.custom_navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; /* Set a higher z-index value to make the buttons appear on top */

}

.custom_prev,
.custom_next {
  background: transparent; /* Set the background to transparent */
  transition: background 0.3s, box-shadow 0.3s; /* Smooth transition effect */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Black shadow with transparency */

  /* background-color: rgb(44, 41, 41); */
  color: white;
  border-radius: 30%;
  padding: 10px;
  cursor: pointer;
}

.custom_prev {
  margin-left: 10px;
}

.custom_next {
  margin-right: 10px;
}


