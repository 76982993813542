

.slider{
    height: 260px;
    padding: .4rem 0rem 0rem 0rem;
    margin: auto;
    position:relative;
    width: 90%;
    flex-shrink: 0;
    background:linear-gradient(to left, rgb(161, 116, 116) 0% ,rgba(70, 49, 49, 0) 0%);
    /* background-color: rgb(84, 82, 82); */
    display: grid;
    place-items: center;
    /*  hide the extra image  */
    overflow: hidden;
    /* margin: 40px; */
    /*perspective to make the image move on z axis depth, we need to add to its container (.slide ) a perspective*/
    perspective:100px;
}
/* .rop{
    height:5px;
    background-color: rgb(106, 105, 105);
    width: 100%;
} */

/*  setting up slider tract */

.slider_track{
    display: flex;
    height: 700px;
    background-color: white;

    width: calc(250px * 10); /*slide track width = totlal number of slides (9*2 = 18)* individual slide width(250px  */
    animation:  scroll 30s linear infinite;

}
@keyframes scroll {
    0%, 100% {
      transform: translateX(0); /* Reset translation at 0% and 100% keyframes */
    }
    25% {
      transform: translateX(calc(-300px)); /* Slide to the left at 25% */
    }
    50% {
      transform: translateX(calc(-600px)); /* Slide to the left at 50% */
    }
    75% {
        transform: translateX(calc(-900px)); /* Slide to the left at 50% */
      }
    /* Continue adding keyframes for each slide */
  }




/* .slide{
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    margin: 15px;

    background-color: red;
} */

.slider_track:hover{
    animation-play-state: paused;
}

.same:hover{
    /* unlike translateX, Y and Z alone won't work */
    transform: translateZ(10px);
    transition: transform .5s;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* height: 250px; */
    background-color: green;


}

.gallerycontainer{
    /* background-color: gray; */
    padding: 4px 0px 0px 0px;
    margin-bottom: 3px;
/* background: linear-gradient(to top right, #87CEFA, #B0E0E6, #ffffff, #f0f0f0); */

  }

.slider img{
    width: 100% !important;
    height: 100% !important;
    transition: transform .5s !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

}
/* image hover effect */

/* img:hover{ */
    /* unlike translateX, Y and Z alone won't work */
    /* transform: translateZ(10px);
    border: 4px solid blue;

} */



/* SHADOW  */

.slider::before,
.slider::after {
    /* background:linear-gradient(to right, rgba(255,255,255,1) 0% ,rgba(167, 118, 118, 0) 100%); */


    content: "";
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
}

.slider::before{
    left: 0;
    top: 0;

}
.slider::after{
    right: 0;
    top: 0;
    transition: rotateZ(180deg);
}

.head{
    margin: 14px 0px 14px 0px ;
    /* color: rgb(11, 95, 95); */
    display: flex;
    /* border: 2px solid red; */
    align-items: center;
    justify-content: center;

    font-size:x-large;
}
.head h2{
    margin: 8px 0px 8px 0px ;
    /* color: rgb(11, 95, 95); */
    border:4px solid #fcb606;
    border-radius: 14px;
    padding: 8px 0px 6px 0px;
    width: 10rem;
    font-size:x-large;
}

.head h2:hover{
    /* color: rgb(11, 95, 95); */
    scale: 1.5 ;
    border:none;


}


/* for different component */
.main{
    background: linear-gradient(to bottom right, #ffffff, #f0f0f0, #87CEFA, #B0E0E6);
    width: 100% ! important;
        padding: 1rem 1rem 5rem 1rem;
}



/* ----------------- end here  */


.hero{
    width: 100%;
    height: 60vh;
    background-color: black;
    /* background-image: url(); */
    position: relative;
    overflow: hidden;

}

/* design for button */

.arrow_button{
    color:#fbfcfd ;
    padding: 10px 25px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 20px;
    outline:none ;
    cursor: pointer;
}

.bubbles img{
    width: 100px;
    animation: bubble 7s linear infinite;/* bubble is only a name which is used to animate */

}
.bubbles img:nth-child(1):hover{
    scale: 1.1;
    animation: none;
    border: 2px solid red;
}

.bubbles{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: -70px;
}

@keyframes bubble{
    0%{
        transform: translateY(0);
        opacity:0;

    }

    50%{
        opacity:1;

    }

    70%{
        opacity:1;


    }

    100%{
        opacity:1;
        transform: translateY(-50vh);

    }

}


/* select the first image */
.bubbles img:nth-child(1){
    animation-delay:2s ;
    width: 150px;
}

.bubbles img:nth-child(2){
    animation-delay:1s ;
    width: 200px;

}

.bubbles img:nth-child(3){
    animation-delay:1s ;
    width: 200px;

}

.bubbles img:nth-child(4){
    animation-delay:3s ;
    width: 150px;

}

.bubbles img:nth-child(5){
    animation-delay:4.5s ;
    width: 200px;

}

.bubbles img:nth-child(6){
    animation-delay:6s ;
    width: 150px;

}

.bubbles img:nth-child(7){
    animation-delay:5.5s ;
    width: 200px;

}

@media only screen and (max-width: 920px) {
    .bubbles{
      background-color: lightblue;
      display:flex;
      flex-direction: column;
    }
  }


  /* for animation  on the gallery section  */

  /* class name  */

  .box{
    position: relative;
    width: 300px;
    height: 215px;
    background: rgba(0,0,0,0.75);
    border-radius: 20px;
    display: flex;
    margin: 20px;
    justify-content: center;
    background-color: black;
    align-items: center;
    overflow: hidden;

  }



  /*  */
  .box::before{
    content: "";
    position: absolute;
    width: 500px;
    height: 500px;
    /* background-image: conic-gradient(transparent,transparent,#00ccff); */
    animation: animate 6s linear infinite;
  }


  .box::after{
    content: "";
    position: absolute;
    width: 800px;
    height: 800px;
    background-image: conic-gradient(transparent,transparent,#d400d4);
    animation: animate 6s linear infinite;
    animation-delay: -3s;
  }




  @keyframes animate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
  }


  .box img{
    position: absolute;
    padding: 5px;
    /* inset: 5px; */
    border-radius: 	20px ;
    /* background:#0c1022; */
    z-index:1;
    /* width: 270px; */
  }



/*  for anoimating the image border on nover  */


  .done{
    color: white;
    display: inline-block;
    padding: 15px 30px;
    border: 2px solid  ;
    color: #111;
    text-decoration: none;
    font-size: 20px;
    font-weight:600 ;
  }


.done::before{
    content: "";
    position:absolute ;

    top: 6px; /*adds |---|  */
    left:-2px ; /* this remove the left side border */
    width: calc (100%+4px) ;    /* this remove the right side border */
    height: calc (100% - 12px); /*|___|  */
    background:white;
    transition: 0.5s ease-in-out;
    transform: scaleY(1);
    transition-delay: 0.5s;
}

.done :hover::before{
    transform: scaleY(0);

}



.done:before{
    content: "";
    position:absolute ;

    left: 6px; /*adds |---|  */
    top:-2px ; /* this remove the left side border */
    height: calc (100%+4px) ;    /* this remove the right side border */
    width: calc (100% - 12px); /*|___|  */
    background:#fff;
    transition: 0.5s ease-in-out;
    transform: scaleX(1);
}

.done :hover::after{
    transform: scaleX(0);

}

.done button{
position: relative;
z-index: 3;
}



/* for rotating  */


.rotating{
    position: relative;
    height: 300px;

    width: 200px;
    background-color: #111	;
    transform-style: preserve-3d;
    animation: animate 20s linear infinite;
}

@keyframes animate{
    0%{
        transform: perspective(1500px) rotateY(0deg);
    }

    0%{
        transform:perspective(1500px) rotateY(360deg);
    }

}

.rotating div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transform-origin: center;
    transform: rotateY(calc(var(--v)* 45deg)) translateZ(300px);

}
.rotating div img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}







/*  for the new hover effect on the gallery section  */


.containerone {
    width: 100%;
    height: 100%;
    padding: 5px 0px 0px 0px;
    /* background-color: white; */
    border-radius: 20px;
    position: relative !important;
    /* padding: 20px; */
    margin: 40px 140px !important;
    /* box-shadow: 0px 10px; */
    padding-bottom:90px ;
}

/* ------------------ for text----------------- */

.container_text {
    width: 100%;
    height: 100%;
    padding: 5px 0px 0px 0px;
    /* background-color: white; */
    border-radius: 20px;
    position: relative !important;
    /* padding: 20px; */
    margin: 40px 140px !important;
    /* box-shadow: 0px 10px; */
    padding-bottom:90px ;
    cursor: pointer;
}


.text_border{
    width: 255px;
    height: 205px;
     display: flex;
     align-items: center;
     justify-content: center;
     position: absolute;
     margin: 0px auto;
     border-radius: 15px;
     /* background-color: 	red; */
     overflow: hidden;
}

.rotating_text_line{
    width: 355px;
    position: absolute;
     height: 150px !important;
     transform: scaleX(8); /* Scale the child div */
     background: linear-gradient(45deg, #e94cdc, #4c28e4);
     rotate: -10deg;
    animation-name: rotatetext;
    position: absolute;
/* background-color: red; */
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: paused;
}

@keyframes rotatetext{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

/* ------------------ for text ends  ----------------- */


.button_border{
    width: 255px;
    height: 205px;
     display: flex;
     align-items: center;
     justify-content: center;
     position: absolute;
     margin: 0px auto;
     border-radius: 15px;
     /* background-color: 	red; */
     overflow: hidden;
}

/*  line which is moving */

.rotating_border_line{
    width: 355px;
    position: absolute;
     height: 150px !important;
     transform: scaleX(8); /* Scale the child div */
     background: linear-gradient(45deg, #e94cdc, #4c28e4);
     rotate: -10deg;
    animation-name: rotate;
    position: absolute;
/* background-color: red; */
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: paused;
}


.button_border:hover .rotating_border_line{
    animation-play-state: running;

}

@keyframes rotate{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}


.button_border img{
    position: absolute;
    width: 290px !important;
    height: 195px !important;
    border-radius: 15px;
    padding: 2px;
    padding: 0px 4px 0px 4px;
    border: none;
    /* background-color: white; */
    cursor: pointer;
    /* opacity: .1; */
}