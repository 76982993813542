/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Hind:wght@100;200;300;400;500;600;700;800;900&display=swap'); */



*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Poppins', sans-serif;
    /* overflow: hidden; */
}

ul{
    list-style: none;
}

a{
    text-decoration: none;
}

header{
    position: sticky;
    top: 0px;
    /* top:5%; */
    background-color: #01519a;
    width: 100%;
    z-index: 1000;
}

/* section{
    position: relative;
    height: calc(100vh - 3rem);
    width: 100%;
    background: url('https://risebot.vercel.app/static/media/img_slider_1.c43d52cd8d084f366743.jpg') no-repeat top center / cover;
    overflow: hidden;
} */

.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #01519a;
}

.container{
    max-width: 140rem;
    /* width: 90%; */
    padding: .5rem 2rem;
    margin: 0 auto;
    display: flex;
    position: relative;

}

.logo_container{
    flex: 1;
    display: flex;
    align-items: center;

}

.nav_btn{
    flex: 3;
    display: flex;

}

.nav_links{
    flex: 2;
}

.log_sign{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.logo{
    color: #fff;
    /* font-size: 1.1rem; */
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 3rem;
}

.logo span{
    font-weight: 300;
}

.btn{
    display: inline-block;
    padding: .5rem 1.3rem;
    font-size: .8rem;
    border: 2px solid #fff;
    border-radius: 2rem;
    line-height: 1;
    margin: 0 .2rem;
    transition: .3s;
    text-transform: uppercase;
}

.btn.solid, .btn.transparent:hover{
    background-color: #fff;
    color: #69bde7;
}

.btn.transparent, .btn.solid:hover{
    background-color: transparent;
    color: #fff;
}

.nav_links > ul{
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav_link{
    position: relative;
}

.nav_link > a{
    line-height: 3rem;
    color: #fff;
    padding: 0 1.1rem;
    letter-spacing: .3px;
    /* font-size: .95rem; */
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .5s;
}

.nav_link > a > i{
    margin-left: .2rem;
}

.nav_link:hover > a{
    transform: scale(1.1);
}

.dropdown{
    position: absolute;
    top: 100%;
    left: 0;
    width: 18rem;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: .5s;
}



.dropdown ul{
    position: relative;
}

.dropdown_link > a{
    display: flex;
    background-color: #fff;
    color: #01519a;
    padding: .5rem 1rem;
    /* font-size: .9rem; */
    font-size: 1.5rem;
    align-items: center;
    justify-content: space-between;
    transition: .3s;
    outline: 1px dotted
    #187446;
    /* border: 2px dotted blue; */
}

.dropdown_link:hover > a{
    background-color: #187446;
    color: #fff;
}

.dropdown_link:not(:nth-last-child(2)){
    border-bottom: 1px solid #efefef;
}

.dropdown_link i{
    transform: rotate(-90deg);
}

.arrow{
    position: absolute;
    width: 11px;
    height: 11px;
    top: -5.5px;
    left: 32px;
    background-color: #fff;
    transform: rotate(45deg);
    cursor: pointer;
    transition: .3s;
    z-index: -1;
}

.dropdown_link:first-child:hover ~ .arrow{
    background-color: #3498db;
}

.dropdown_link{
    position: relative;
}

.dropdown.second{
    top: 0;
    left: 100%;
    padding-left: .8rem;
    cursor: pointer;
    transform: translateX(10px);
}

.dropdown.second .arrow{
    top: 10px;
    left: -5.5px;
}

.nav_link:hover > .dropdown,
.dropdown_link:hover > .dropdown{
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
}

.hamburger_menu_container{
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.hamburger_menu{
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.hamburger_menu div{
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    z-index: 1001;
    transition: .5s;
}

.hamburger_menu div:before,
.hamburger_menu div:after{
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: #fff;
    border-radius: 3px;
    transition: .5s;
}

.hamburger_menu div:before{
    transform: translateY(-7px);
}

.hamburger_menu div:after{
    transform: translateY(7px);
}

.check{
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
    display: none;
}

.check:checked ~ .hamburger_menu_container .hamburger_menu div{
    background-color: transparent;
}

.check:checked ~ .hamburger_menu_container .hamburger_menu div:before{
    transform: translateY(0) rotate(-45deg);
}

.check:checked ~ .hamburger_menu_container .hamburger_menu div:after{
    transform: translateY(0) rotate(45deg);
}

@keyframes animation{
    from{
        opacity: 0;
        transform: translateY(15px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}



@media (max-width: 1214px){
    .hamburger_menu_container{
        display: flex;
        /* position: relative; */
    }

    .check{
        display: block;
    }


    /* .check{
        display: none !important;
    } */



    .nav_btn{
        /* position: fixed; */
        position: absolute;
       max-height: calc(100vh - 17rem);

        /* top: 3rem; */
        left: 0;
        width: 100%;
        /* background-color: #69bde7; */
        background-color: #01519a;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow-x: hidden;
        overflow-y: auto;

        transform: translateX(100%);
        transition: .65s;
    }

    .check:checked ~ .nav_btn{
        transform: translateX(0);
        display: block;
    }


    .check ~ .nav_btn{
        transform: translateX(0);
        display: none;
    }


    .check:checked ~ .nav_btn .nav_link,
    .check:checked ~ .nav_btn .log_sign{
        animation: animation .5s ease forwards var(--i);
    }

    .nav_links{
        flex: initial;
        width: 100%;
    }

    .nav_links > ul{
        flex-direction: column;
        /* border: 2px solid green; */
        /* display: none; */
    }

    .nav_link{
        width: 100%;
        opacity: 0;
        transform: translateY(15px);
    }

    .nav_link > a{
        line-height: 1;
        padding: 1.6rem 2rem;
    }

    .nav_link:hover > a{
        transform: scale(1);
        background-color: #50a9d6;
    }

    .dropdown, .dropdown.second{
        position: initial;
        top: initial;
        left: initial;
        transform: initial;
        opacity: 1;
        pointer-events: auto;
        width: 100%;
        padding: 0;
        background-color: #3183ac;
        display: none;
    }

    .nav_link:hover > .dropdown,
    .dropdown_link:hover > .dropdown{
        display: block;
    }

    .nav_link:hover > a > i,
    .dropdown_link:hover > a > i{
        transform: rotate(360deg);
    }

    .dropdown_link > a{
        background-color: transparent;
        color: #fff;
        padding: 1.2rem 2rem;
        line-height: 1;
    }

    .dropdown.second .dropdown_link > a{
        padding: 1.2rem 2rem 1.2rem 3rem;
    }

    .dropdown.second .dropdown.second .dropdown_link > a{
        padding: 1.2rem 2rem 1.2rem 4rem;
    }

    .dropdown_link:not(:nth-last-child(2)){
        border-bottom: none;
    }

    .arrow{
        z-index: 1;
        background-color: #69bde7;
        left: 10%;
        transform: scale(1.1) rotate(45deg);
        transition: .5s;
    }

    .nav_link:hover .arrow{
        background-color: #50a9d6;
    }

    .dropdown .dropdown .arrow{
        display: none;
    }

    .dropdown_link:hover > a{
        background-color: #3a91bd;
    }

    .dropdown_link:first-child:hover ~ .arrow{
        background-color: #50a9d6;
    }

    .nav_link > a > i{
        font-size: 1.1rem;
        transform: rotate(-90deg);
        transition: .7s;
    }

    .dropdown i{
        font-size: 1rem;
        transition: .7s;
    }

    .log_sign{
        flex: initial;
        width: 100%;
        padding: 1.5rem 1.9rem;
        justify-content: flex-start;
        opacity: 0;
        transform: translateY(15px);
    }
}

.search :hover{
    cursor: pointer;

}