.title{
    margin-top: 50px;
    font-size: 24px;
    color: rgb(123, 41, 13);;
    /* padding-bottom: 15px; */
}

.game_sgfi{
    display: flex;
    justify-content: center;
    gap: 25px;
    padding-bottom: 20px;
    padding-top: 50px;
    flex-wrap: wrap;

    /* border: 2px solid red; */
}
.game_card{
    box-shadow: 0 0 10px 5px #bfc2c6;
    color: rgb(1, 105, 56);
    transition: color 0.25s 0.083333s;
    position: relative;
    /* background-color: blue; */
    /* border: 2px solid green;  */
    transition: transform .2s;
    /* opacity: 100000; */
    font-size: 22px;
    font-weight: 900;

}
.game_card p{
    padding-bottom: 15px;
}
.game_card:hover{
    border-radius: 15px;
    transform: scale(1.15);
    cursor: pointer;

}
.game_card img:hover{
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    /* transform: scale(1.15); */
    cursor: pointer;

}

/* .imgsgfi{
    background-image: url('../../assets/images/Anjani.jpeg');
    z-index: 56;
} */




@media only screen and (max-width: 600px) {
    .text_tab {
      font-size: 8px !important;
    }
  }